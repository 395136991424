import $http from '@/utils/http'

export function doGet(url) {
    return $http.get(url);
}
export function doPost(url, data) {
    return $http.post(url, data);
}

/**
 * 菜单导航接口
 */
export function getMenu(type) {
	return $http.get('/api/phone/v1/menu?type='+type);
}
/**
 * 根据类型获取单篇文章内容
 */
export function getOneWzContent(kindId) {
    return $http.get('/api/phone/v1/getOneWzContent?kindId='+kindId);
}
/**
 * 根据类型获取文章集合
 */
export function getWzContentList(kindId) {
    return $http.get('/api/phone/v1/getWzContentList?kindId='+kindId);
}

/**
 * 根据路径查询菜单导航接口
 */
export function menuByUrl(type,path) {
    return $http.get('/api/phone/v1/menuByUrl?type='+type+'&path='+path);
}
/**
 * 展示文章内容
 */
export function getWzContentDetail(id) {
    return $http.get('/api/phone/v1/getWzContentDetail?id='+id);
}
/**
 * 根据类型获取文章分页集合
 */
export function getWzContentPage(data) {
    return $http.post('/api/phone/v1/getWzContentPage',data);
}
/**
 * 根据类型获取视频分页集合
 */
export function getWzVideoPage(data) {
    return $http.post('/api/phone/v1/getWzVideoPage',data);
}
/**
 * 根据类型获取文件分页集合
 */
export function getWzFilePage(data) {
    return $http.post('/api/phone/v1/getWzFilePage',data);
}
/**
 * 获取热点资讯集合
 */
export function getHotWzContentPage(data) {
    return $http.post('/api/phone/v1/getHotWzContentPage',data);
}
/**
 * 根据类型获取图片分页集合
 */
export function getWzImagePage(data) {
    return $http.post('/api/phone/v1/getWzImagePage',data);
}
/**
 * 根据菜单导航id获取导航列表接口
 */
export function menuListById(id) {
    return $http.get('/api/phone/v1/menuListById?id='+id);
}
/**
 * 查询底部网站链接地址接口
 */
export function webPathList() {
    return $http.get('/api/phone/v1/webPathList');
}
/**
 * 系统配置查询
 */
export function getSysConfigByFlage(flage) {
    return $http.get('/api/phone/v1/getSysConfigByFlage?flage='+flage);
}
/**
 * 候选人申请接口
 */
export function addHxr(data) {
    return $http.post('/api/phone/v1/addHxr',data);
}