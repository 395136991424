import Axios from "axios"
import { Message } from 'element-ui'
import qs from 'qs';

const $http = Axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000 // request timeout
});

function getRequestParams (name) {
	var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
	var r = window.location.search.substr(1).match(reg);
	return r != null ? (r[2]) : '';
}

$http.interceptors.request.use(config => {
	let token = getRequestParams("token");
		if (token) {
			config.headers["token"] = token;
		}
		config.data = qs.stringify(config.data);
		return config;
	},
	error => {
			return Promise.reject(error);
	}
);
// After request
$http.interceptors.response.use(
    response => {
		// console.debug(response)
		if(response.status == 200){
			if (response.data.code == 0) {
				return Promise.resolve(response.data);
			} else if (response.data.code == 2) {
				window.location.href = "/dist/notoken";
			} else if(response.data.msg) {
				Message({
				  message: response.data.msg || 'Error',
				  type: 'error',
				  duration: 5 * 1000
				})
				return Promise.reject(response).catch(() => {});
			}
		}  else {
			// console.error(response.message)
			Message({
			  message: response.message || 'Error',
			  type: 'error',
			  duration: 5 * 1000
			})
			return Promise.reject(response).catch(() => {});
		}
    },
    error => {
		// console.debug(error.response)
        const response = error.response.data;
        switch (response.status) {
            default:
				// console.error(response);
				Message({
				  message: response.message || 'Error',
				  type: 'error',
				  duration: 5 * 1000
				})
                return Promise.reject(error).catch(() => {});
        }
    }
);

export default $http;